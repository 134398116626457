/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {

      init: function() {
        // JavaScript to be fired on all pages
        var $body = $('body');


        // Lazy load images
        $('.lazy').Lazy({
          effect: 'fadeIn',
          effectTime: 250,
          threshold: 0
        });

        // Hideseek filters on people archive pages
        if($('#people-search').length){
          $('#people-search').hideseek({
            attribute: 'text',
            nodata: 'Nobody found.'
          });
        }

        // Very specific hack for Elementor plugin
        // requires tab element to have a .last-external class
        // requires the last tab to have a link inside with a data attribute
        if($('.last-external').length > 0){
          $('.last-external').find('.elementor-tab-title:last-of-type').on('click', function(e){
            var tab = $(this).attr('data-tab'),
              $content = $('.elementor-tabs-content-wrapper').find('.elementor-tab-content[data-tab="' + tab + '"]');

            var link = $content.find('a');
            if(link){
              window.open(link.attr('href'), '_blank');
            }
          });
        }

        // If spotlight wrapper exists on page, initilize slick
        if($('.spotlight-wrapper').length > 0){
          var $spotlight = $('.spotlight-wrapper');
          $spotlight
            .on('init', function(slick){
              $spotlight.addClass('loaded');
            })
            .slick({
              dots: true,
              arrows: false,
              autoplay: true,
              draggable: false,
              autoplaySpeed: 3500,
              lazyLoad: 'ondemand',
              fade: true
            });
        }


        // Make sure resources fit masonry
        var $grid = $('.resource-grid');
        $grid.masonry({
          isAnimated: true,
          initLayout: false,
          itemSelector: '.resource-grid__item'
        });
        $grid.masonry('on', 'layoutComplete', function(){
          $grid.addClass('loaded');
        });
        $grid.masonry();


        // Global Tab toggle
        $('.fms-tabs__nav a:not(.tab-target)').on('click', function(e){
          e.preventDefault();
          var $tabs = $(this).closest('.fms-tabs'),
            target = $(this).data('target');

          var $nav = $(this).closest('.fms-tabs__nav'),
            $content = $tabs.find('.fms-tabs__content');

          $nav.find('li').removeClass('active');
          $(this).parent().addClass('active');

          $content.find('.fms-tab').removeClass('active');
          $content.find('.fms-tab--' + target).addClass('active');
          $(window).trigger("scroll");
        });

        // Currently only on the Gallery page but could be more global eventually
        // Need a better way of calculating the final offset on click
        $('.fms-tabs__nav a.tab-target').on('click', function(e){
          e.preventDefault();
          var $target = $(this).data('target');
          var offset = 77; // This is final height of the main nav (since we're moving down the page we can't get the final height on initialization)
          $('html, body').animate({ scrollTop: $('#'+ $target).offset().top - offset }, 500);
        });


        // Mobile navigation toggle
        $('.mobile-nav-toggle').on('click', function(e){
          e.preventDefault();
          $body.addClass('nav--open');
          $('.mobile-nav-menu').addClass('active');
        });
        $('.mobile-nav-close').on('click', function(e){
          e.preventDefault();
          $body.removeClass('nav--open');
          $('.mobile-nav-menu').removeClass('active');
        });


        // All the fun size changing for main nav
        var $nav = $('.fms-banner'),
          $topbar = $('.fms-topbar');

        $(window).scroll(function(){
          if($(this).scrollTop() > $topbar.height() - 2){ // 2px for appearance of top border
            $('body').addClass('fix-nav');
          }
          else{
            $('body').removeClass('fix-nav');
          }

          if($(this).scrollTop() > ($topbar.height() * 2)){
            $nav.addClass('small');
          }
          else{
            $nav.removeClass('small');
          }
        });

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // alert('home-page');
        //var slider = new Slick('.fms-slider', options);
        var $slider = $('.fms-slider');
        $slider
          .on('init', function(slick){
            $slider.addClass('loaded');
          })
          .slick({
              dots: false,
              arrows: true,
              autoplay: true,
              draggable: false,
              autoplaySpeed: 3500,
              lazyLoad: 'ondemand',
              fade: true
          });
        // $('.lazy-slide').Lazy({
        //   effect: 'fadeIn',
        //   effectTime: 250,
        //   threshold: 0,
        //   onFinishedAll: function(){
        //     $slider.css('opacity', 1);
        //   }
        // });


        // Research interests typeit
        var $research = $('.fms-home__research');
        var termjson = $.parseJSON($research.find('.research-data').attr('data-research'));

        var tags = new TypeIt('#tag_rotation', {
             strings: termjson,
             speed: 150,
             breakLines: false,
             autoStart: false,
             loop: true,
             nextStringDelay: 3000
        });


      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS

      }
    },
    // About us page, note the change from about-us to about_us.
    'about': {
      init: function() {

        var $body = $('body');

      }
    },
    'interests': {
      init: function(){
        function escapeHtml(text) {
          var map = {
            '&': '&amp;',
            '<': '&lt;',
            '>': '&gt;',
            '"': '&quot;',
            "'": '&#039;'
          };

          return text.replace(/[&<>"']/g, function(m) { return map[m]; });
        }


        var tree,
          $body = $('body'),
          pageData = $.parseJSON($('.interests-json').attr('data-json')),
          researchInterests = {
            "name": "Research Interests",
            "children": pageData
          };



        // Helper functions
        // Get URL Param
        var getUrlParameter = function getUrlParameter(sParam) {
            var sPageURL = decodeURIComponent(window.location.search.substring(1)),
                sURLVariables = sPageURL.split('&'),
                sParameterName,
                i;

            for (i = 0; i < sURLVariables.length; i++) {
                sParameterName = sURLVariables[i].split('=');

                if (sParameterName[0] === sParam) {
                    return sParameterName[1] === undefined ? true : sParameterName[1];
                }
            }
        };
        // Check if array has object
        function containsObject(obj, list) {
            var i;
            for (i = 0; i < list.length; i++) {
                if (list[i] === obj) {
                    return true;
                }
            }

            return false;
        }

        // Drawer Stuff
        function buildDrawer(entity){
          var $drawer = $('.fms-viz__drawer');
          // console.log(entity);
          $drawer.removeClass('active').children().remove();

          var data = {
            "title": entity.name,
            "thumb": entity.thumb,
            "url": entity.url,
            "other": entity.other
          };

          var template = '<div class="viz-drawer"><a class="close-drawer" href="#"></a><div class="viz-drawer__thumb"><img src="' + data.thumb + '"></div><h2>' + data.title + '</h2><p>Interests</p><ul class="other-interests">';

          if(data.other){
            $.each(data.other, function(i, interest){
              template += '<li>' + interest + '</li>';
            });
          }
          template += '</ul><a href="' + data.url + '">View ' + entity.type + '</a></div>';
          $drawer.append(template).addClass('active');
        }

        $body.on('click', '.close-drawer', function(e){
          e.preventDefault();
          $(this).closest('.fms-viz__drawer').removeClass('active');
        });

        // Modify d3 tree with a few things so we can add color and properties to nodes
        d3.chart('tree.radial').extend('RadialTree', {
          initialize: function(){
            var chart = this;

            chart.d3.zoom.translate([chart.options.width / 2, chart.options.diameter / 2]);
            chart.layers.base
              .attr("transform", "translate(" + chart.options.width / 2 + "," + chart.options.diameter / 2 + ")");

            chart.layers.nodes.on('enter', function(){
              var layers = this;
              layers.each(function(el, i){
                if(!el.parent){
                  $(this).addClass('top-term');
                }
                if(el.isLeaf){
                  if(el.type !== undefined){
                    $(this).addClass(el.type.toLowerCase());
                  }
                  $(this).on('click', function(){
                    buildDrawer(el);
                  });
                }
              });
            });
          }
        });




        // Build the tree viz
        // Started to modularize this for use on other pages
        // TODO: Make accessible to other pages
        // Canvas where the viz lives, how many levels to show, and data source
        function buildTree(canvas, level, data){
          // Remove svg if already exists for redraw

          $(canvas).find('svg').remove();
          // $(canvas).css({
          //   'height': $(canvas).parent().height(),
          //   'width': $(canvas).parent().width()
          // });

          tree = d3.select(canvas).append("svg")
            .chart("RadialTree")
            .levelGap(250)
            .duration(200)
            //.sortable("_ASC_")
            .zoomable([0.1, 3])
            .collapsible(level);

          tree.draw(data);
        }


        function setVizHeight(){
          var $viz = $('.fms-viz__viz'),
            $header = $('.fms-viz__header'),
            vizHeight = window.innerHeight - ($header.outerHeight() + $('.top-bar').outerHeight() + $('.fms-banner').outerHeight() + $('.fms-footer').height());
          // Need to rework this vizheight calculation

          $viz.css('height', '600px');
        }

        setVizHeight();


        // In case arriving from single interest
        // Check for URL Params
        var researchTerm = getUrlParameter('term');
        if(researchTerm){
          var finalObj = {
            "name": "Research Interests",
            "children": []
          };

          var storedTerm;
          $.each(pageData, function(i, parentTerm){
            // Top level terms
            $.each(parentTerm.children, function(i, term){

              if(term.name === researchTerm && parentTerm.name !== 'Uncategorized' && term.name !== storedTerm){
                finalObj.children.push(term);
                storedTerm = term.name;
              }

            });
          });
          // console.log(finalObj);
          buildTree('#treeviz', 2, finalObj);

        }
        else{
          buildTree('#treeviz', 3, researchInterests);
        }


        $('a[data-action="all"]').on('click', function(e){
          e.preventDefault();
          buildTree('#treeviz', 2, researchInterests);
        });
        $('a[data-action="cats"]').on('click', function(e){
          e.preventDefault();
          buildTree('#treeviz', 1, researchInterests);
        });

        $(window).on('resize', function(){
          if(!researchTerm){
            setVizHeight();
            buildTree('#treeviz', 3, researchInterests);
          }
        });



      }
    },
    // About us page, note the change from about-us to about_us.
    'people': {
      init: function() {
        // var $spotlight = $('.spotlight-wrapper');
        // $spotlight.slick({
        //   dots: true,
        //   arrows: false,
        //   autoplay: true,
        //   draggable: false,
        //   autoplaySpeed: 3500,
        //   fade: true
        // });
        // $spotlight.show();
      }
    },
    'research': {
      init: function() {
        var $journals = $('.carousel--journals');
        $journals
          .on('init', function(slick){
            $journals.addClass('loaded');
          })
          .slick({
              slidesToShow: 3,
              slidesToScroll: 3,
              dots: true,
              arrows: false,
              autoplay: true,
              draggable: false,
              infinite: false,
              lazyLoad: 'ondemand',
              autoplaySpeed: 3500
          });

        $('.carousel--publications').each(function(){
          var $pub = $(this);
          $pub
            .on('init', function(slick){
              $pub.addClass('loaded');
            })
            .slick({
                slidesToShow: 2,
                slidesToScroll: 2,
                dots: true,
                arrows: false,
                autoplay: true,
                draggable: false,
                infinite: false,
                lazyLoad: 'ondemand',
                autoplaySpeed: 3000,
                responsive: [
                  {
                    breakpoint: 1024,
                    settings: {
                      slidesToShow: 3,
                      slidesToScroll: 3,
                      infinite: true,
                      dots: true
                    }
                  },
                  {
                    breakpoint: 600,
                    settings: {
                      slidesToShow: 2,
                      slidesToScroll: 2
                    }
                  },
                  {
                    breakpoint: 480,
                    settings: {
                      slidesToShow: 1,
                      slidesToScroll: 1
                    }
                  }
                  // You can unslick at a given breakpoint now by adding:
                  // settings: "unslick"
                  // instead of a settings object
                ]
            });

        });
        var $creative = $('.carousel--creative');
        $creative
          .on('init', function(slick){
            $creative.addClass('loaded');
          })
          .slick({
              slidesToShow: 3,
              slidesToScroll: 3,
              dots: true,
              arrows: false,
              autoplay: true,
              draggable: false,
              infinite: false,
              lazyLoad: 'ondemand',
              autoplaySpeed: 3500,
              responsive: [
                {
                  breakpoint: 1024,
                  settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                  }
                },
                {
                  breakpoint: 600,
                  settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
                  }
                },
                {
                  breakpoint: 480,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                  }
                }
                // You can unslick at a given breakpoint now by adding:
                // settings: "unslick"
                // instead of a settings object
              ]
          });
        $(window).trigger("scroll");

      }
    },
    'courses': {
      init: function() {
        var $tabs = $('.fms-tabs'),
          $nav = $tabs.find('.fms-tabs__nav'),
          $content = $tabs.find('.fms-tabs__content'),
          month = $tabs.attr('data-month');

        // $nav.find('li').removeClass('active');
        // $content.find('.fms-tab').removeClass('active');

        // if(month > 2 && month <= 6 ){
        //   $nav.find('li:nth-of-type(3)').addClass('active');
        //   $content.find('.fms-tab:nth-of-type(3)').addClass('active');
        // }
        // else if(month > 6 && month <= 9){
        //   $nav.find('li:last-of-type').addClass('active');
        //   $content.find('.fms-tab:last-of-type').addClass('active');
        // }
        // else if(month > 9 && month <= 12){
        //   $nav.find('li:first-of-type').addClass('active');
        //   $content.find('.fms-tab:first-of-type').addClass('active');
        // }
        // else if(month > 0 && month <= 2){
        //   $nav.find('li:nth-of-type(2)').addClass('active');
        //   $content.find('.fms-tab:nth-of-type(2)').addClass('active');
        // }
      }
    },
    'gallery': {
      init: function() {
        var $slider = $('.carousel--creative');
        $slider
          .on('init', function(slick){
            $slider.addClass('loaded');
          })
          .slick({
              slidesToShow: 3,
              slidesToScroll: 3,
              dots: true,
              arrows: false,
              autoplay: true,
              draggable: false,
              infinite: false,
              lazyLoad: 'ondemand',
              autoplaySpeed: 3500,
              responsive: [
                {
                  breakpoint: 1024,
                  settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                  }
                },
                {
                  breakpoint: 600,
                  settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
                  }
                },
                {
                  breakpoint: 480,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                  }
                }
                // You can unslick at a given breakpoint now by adding:
                // settings: "unslick"
                // instead of a settings object
              ]
          });
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');

      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
